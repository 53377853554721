@tailwind base;
@tailwind components;
@tailwind utilities;
@import url("https://fonts.googleapis.com/css2?family=Gotu&display=swap");

.single-page h1 {
  font-size: 2.5em;
  color: white;
  padding-bottom: 0.74em;
}

.max-w-lgdesktop {
  max-width: 1600px;
}

/* body {
  background-color: rgb(11 10 12);
} */

.single-page h2 {
  font-size: 1.5em;
  color: white;
  padding-bottom: 0.5em;
}

.single-page ul {
  padding-bottom: 2em;
  list-style-type: disc;
  margin-left: 2em;
  display: block;
}

.single-page p {
  padding-bottom: 1.2em;
}

.liner {
  width: 100%;
  height: 1px;
  background: #564ce6;
}
.liner-box {
  width: 10px;
  height: 10px;
  border: 1px solid #564ce6;
}

body,
html {
  height: 100%;
  font-size: 16px;
  scroll-behavior: smooth;
}

body {
  font-family: futura-pt, sans-serif;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
}

a:hover {
  color: #574de6;
  text-decoration: none !important;
}

.hover-zoom {
  transition: transform 3s ease-in-out;
}

.hover-zoom:hover {
  transform: scale(1.2);
}

form label {
  margin: 1em 0 !important;
  display: block;
  color: #71707d;
}

.hs_lastname.hs-lastname.hs-fieldtype-text.field.hs-form-field,
.hs_firstname.hs-firstname.hs-fieldtype-text.field.hs-form-field {
  width: 50%;
}

fieldset.form-columns-2 {
  display: flex;
  justify-content: space-between;
}

form textarea {
  min-height: 220px;
}
form textarea,
form select,
form input {
  border-radius: 6px;
  background: #cdcdcd;
  padding: 1em;
  width: 100% !important;
}

form fieldset {
  max-width: 100% !important;
}
form label.hs-error-msg {
  background: #ff9393;
  color: red;
  padding: 0.5rem;
  display: table;
  border-radius: 5px;
}

form input.hs-button.primary.large {
  background: #574de6;
  border-radius: 50px;
  color: white;
  margin-top: 30px;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: futura-pt, sans-serif;
}

.active-menu {
  position: relative;
}

.active-menu a {
  border-radius: 25px;
  color: white;
  background: black;
}

#lottie-logo div {
  margin: inherit !important;
}

.pt-3 {
  padding-top: 3em;
}

.hs-form-029c4df0-c728-4b44-9cbe-0da4ea7f1f66_b2f3f7e6-bb56-40b0-abaf-447c36810df0
  fieldset.form-columns-2
  .input {
  margin-right: 8px !important;
}

.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 750ms ease-out, transform 750ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 750ms ease-out, transform 750ms ease;
}
